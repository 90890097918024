import React from 'react'

const OrderBooklet = () => {
    return (
        <div className="card banner my-4 mx-auto">
            <div className="my-2">
                <h5 className="text-center">
                    You can now also buy a printed booklet!
                </h5>
            </div>
            <div className="banner-white">
                <a href="https://www.amazon.com/dp/B08P8D724N" target="_blank">
                    <img
                        src={require('../img/two-covers-one-print-one-cactus.jpg')}
                        className="card-img-top text-center"
                    />
                    <div className="my-2">
                        <h6 className="text-center">Order from Amazon</h6>
                    </div>
                </a>
            </div>
        </div>
    )
}

export default OrderBooklet
