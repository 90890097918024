import React from 'react'

import { Link } from 'react-router-dom'

export default class Booklet extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div>
                <nav className="navbar-light bg-color">
                    <Link to={`../main`} className="navbar-brand py-4">
                        <img
                            src={require('../img/icon_ColorMeLife_simple.svg')}
                            className="brand-icon-sm"
                            alt="Coloring pages for kids and adults"
                            title="Coloring pages for kids and adults"
                        />
                    </Link>
                </nav>
                <div className="container">
                    <div className="my-5">
                        <h2 className="text-center">Order your booklet</h2>
                        <h5 className="my-5 text-center">
                            Our beautiful coloring booklets are out!
                        </h5>
                        <p className="text-center">
                            All booklets are printed on recycled paper.
                        </p>
                        <p>
                            You can select any number of pages from any collection. Or you can even go for a full big-format drawing of one collection,
                            which is the combination of all images from one collection into one beautiful complex ecosystem!
                        </p>
                        <p>
                            We are working on this part of the site so that you can order them easily and we'll have this functioning soon.
                        </p>
                        <p>
                            If you can't wait (which we definitely understand) message us on <a href="https://www.facebook.com/TheColormeLife/" target="_blank">Facebook</a> or <a href="https://www.instagram.com/colorme_life/" target="_blank">Instagram</a> to request your print.
                        </p>
                        <div className="text-center mb-5">
                            <a
                                href="https://www.instagram.com/colorme_life/"
                                title="#colormeLife"
                                className="btn-clipboard"
                            >
                                <img
                                    src={require('../img/ic_social_instagram.svg')}
                                    className=""
                                    alt="#colormeLife"
                                />
                            </a>
                        </div>
                        <div className="">
                            <img src={require('../img/three-covers-one-print.png')} className="img-fluid" />
                        </div>
                        <h6 className="my-5 text-center">Coloring life is the perfect gift!</h6>
                        <h5 className="text-center">♥</h5>
                    </div>
                </div>

                <div className="footer p-3">
                    <div className="my-2 text-center">
                        <img src={require('../img/instagram_phone.png')} width="120" className="mx-auto d-block p-3"/>
                        <p className="text-white">
                            <span className="font-weight-bold">
                                    <a
                                        href="https://www.instagram.com/colorme_life/"
                                        target="_blank"
                                    >
                                        @colorme_life.
                                    </a>{' '}
                                #ColormeLifeCollections
                                </span>
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}

