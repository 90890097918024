import React from 'react'

import { Link } from 'react-router-dom'

export default class Artists extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div>
                <nav className="navbar-light bg-color">
                    <Link to={`../main`} className="navbar-brand py-4">
                        <img
                            src={require('../img/icon_ColorMeLife_simple.svg')}
                            className="brand-icon-sm"
                            alt="Coloring pages for kids and adults"
                            title="Coloring pages for kids and adults"
                        />
                    </Link>
                </nav>
                <div className="container">
                    <div className="my-5 text-center narrow-col">
                        <h2 className="my-5">Become a wildlife hero!</h2>
                        <h5>They say that with great power, comes great responsibility.
                        </h5>
                        <p>
                            Our species is incredibly powerful, we have amazing brains and (generally) physical capacities that can't be compared to other species in this world.
                            We now more than ever need to take responsibility to protect other species from the intended or unintended consequences of our human activity.
                        </p>
                        <h6 className="mt-5">
                            Some simple and obvious rules to join as an artist:
                        </h6>
                        <ul className="my-2 text-left px-5">
                            <li>
                                Only upload your own work. You must be the sole owner and have ALL rights on the work you upload.
                            </li>
                            <li>
                                Make sure your files are virus-free before uploading.
                            </li>
                            <li>
                                Your drawings must be suitable for everyone, even the most innocent eyes.
                            </li>
                        </ul>

                        <div className="card banner my-5 p-5 mx-auto">
                            <h3>Submit your art</h3>
                            <hr/>
                            <h6>
                                Please prepare a folder with the following:
                            </h6>
                            <ol className="text-left">
                                <li>
                                    A simple text file with your <b>name and email</b> so that we can contact you about your art submission.
                                </li>
                                <li>
                                    Your art image or images, in black over transparent background. In .svg, pdf, or png format.
                                </li>
                            </ol>
                            <small className="my-4">
                                Make sure each image is high quality but doesn't exceed 1MB. <br />A good tip is using tinypng.org
                            </small>
                            <hr/>
                            <h6 className="mt-2">
                                Name your folder after yourself and upload it here:
                            </h6>
                            <p>
                                <a className="mt-4 btn btn-primary" href="https://www.dropbox.com/request/prTGf7BNgEgkUEcNuWps" target="_blank">
                                    Upload your work
                                </a>
                            </p>
                        </div>
                        <div className="mb-5">
                        <p>
                            We'll contact you as soon as we have reviewed your submission.
                        </p>
                            <small>
                                We cannot guarantee that your work will be accepted, but we'll anyway contact you back.
                            </small>
                        </div>
                        <h4>
                            Thanks for being a wildlife hero!
                        </h4>
                    </div>
                </div>

                <div className="footer p-3">
                    <div className="my-2 text-center">
                        <img src={require('../img/instagram_phone.png')} width="120" className="mx-auto d-block p-3"/>
                        <p className="text-white">
                            <span className="font-weight-bold">
                                    <a
                                        href="https://www.instagram.com/colorme_life/"
                                        target="_blank"
                                    >
                                        @colorme_life.
                                    </a>{' '}
                                #ColormeLifeCollections
                                </span>
                        </p>
                    </div>
                </div>

            </div>
        )
    }
}

