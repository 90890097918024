import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import Download from './download'
import Main from './main'
import Booklet from './booklets'
import Artists from './artists'

import './styles.css'
import {
    africaCollection,
    amazonCollection,
    arcticCollection,
    coralCollection,
    vegetationCollection,
    whalesCollection,
} from './collection'

const getCollection = name => {
    switch (name) {
        case 'africa':
            return africaCollection
        case 'amazon':
            return amazonCollection
        case 'arctic':
            return arcticCollection
        case 'coral':
            return coralCollection
        case 'vegetation':
            return vegetationCollection
        case 'whales':
            return whalesCollection
        default:
            return whalesCollection
    }
}

class App extends Component {
    constructor(props) {
        super(props)
    }

    routes = () => {
        return (
            <Router>
                <div>
                    <Switch>
                        <Route exact path="/main/:activeTab" component={this.MainRoute} />
                        <Route
                            exact
                            path="/download/:collection/:selectedImgSrc"
                            component={this.DownloadRoute}
                        />
                        <Route exact path="/download/:collection" component={this.DownloadRoute} />
                        <Route exact path="/booklets" component={this.BookletsRoute} />
                        <Route path="/artists" component={this.ArtistsRoute} />
                        <Route // no match
                            component={this.MainRoute}
                        />
                    </Switch>
                </div>
            </Router>
        )
    }

    DownloadRoute = ({ match }) => (
        <Download
            collection={getCollection(match.params.collection)}
            selectedImgSrc={match.params.selectedImgSrc || ''}
        />
    )

    BookletsRoute = ({ }) => (
        <Booklet
        />
    )

    ArtistsRoute = ({ }) => (
        <Artists
        />
    )

    MainRoute = ({ match }) => <Main activeTab={match.params.activeTab || 'whales'} />

    render() {
        return <div>{this.routes()}</div>
    }
}

ReactDOM.render(<App />, document.getElementById('app'))
