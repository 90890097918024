/* eslint-disable react/no-unescaped-entities */
import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Link, NavLink, Redirect } from 'react-router-dom'
import { Nav, NavItem, Tooltip, Button } from 'reactstrap'

import { Carousel, CarouselItem, CarouselControl, CarouselIndicators } from 'reactstrap'

import { isPaymentBetweenLimits } from '../../../common/validation'

import TakeMoney from '../takemoney'
import { imgIdFrom } from '../common/image-id'
import OrderBooklet from '../common/orderBooklet'

const isValidDonation = inputValue => {
    if (isNaN(inputValue)) {
        return false
    }

    const sum = numberInputToCents(inputValue)
    return isPaymentBetweenLimits(sum)
}

const numberInputToCents = inputValue => Math.trunc(parseFloat(inputValue) * 100)
const centsToDollars = cents => (cents / 100).toFixed(2)

export default class Download extends Component {
    constructor(props) {
        super(props)

        const getActiveIndex = (collection, imgSrc) => {
            const matchingIndex = collection.images.findIndex(el => el.includes(imgSrc))
            return matchingIndex === -1 ? 0 : matchingIndex
        }

        this.toggleDownload = this.toggleDownload.bind(this)
        this.toggleTooltip = this.toggleTooltip.bind(this)

        this.state = {
            activeIndex: getActiveIndex(props.collection, props.selectedImgSrc),
            toWildlifeInCents: 500,
            toWildlifeInputValue: '5.00',
            toWildlifeValid: true,
            toArtistInCents: 500,
            toArtistInputValue: '5.00',
            toArtistValid: true,
            paidImageIndex: 0,
            paymentError: false,
            paymentSuccess: false,
            tooltipOpen: false,
            shown: false,
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    toggleTooltip() {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen,
        })
    }

    toggleDownload() {
        this.setState({
            shown: !this.state.shown,
        })
    }

    handlePaymentSuccess = () => {
        // eslint-disable-next-line no-console
        console.log('successful payment')
        this.setState({
            paymentSuccess: true,
            paymentError: false,
            paidImageIndex: this.state.activeIndex,
        })
    }

    handlePaymentError = () => {
        // eslint-disable-next-line no-console
        console.log('error when handling payment')
        this.setState(state => {
            state.paymentSuccess = false
            state.paymentError = true
            return state
        })
    }

    onExiting = () => {
        this.animating = true
    }

    onExited = () => {
        this.animating = false
    }

    next = () => {
        if (this.animating) return
        const nextIndex =
            this.state.activeIndex === this.props.collection.images.length - 1
                ? 0
                : this.state.activeIndex + 1
        this.setState({ activeIndex: nextIndex })
    }

    onToWildlifeChange = e => {
        const newValue = e.target.value
        const isValid = isValidDonation(newValue)
        const toWildlife = isValid ? numberInputToCents(newValue) : this.state.toWildlifeInCents

        this.setState(state => {
            state = {
                toWildlifeInCents: toWildlife,
                toWildlifeInputValue: newValue,
                toWildlifeValid: isValid,
            }
            return state
        })
    }

    onToArtistChange = e => {
        const newValue = e.target.value
        const isValid = isValidDonation(newValue)
        const toArtist = isValid ? numberInputToCents(newValue) : this.state.toArtistInCents

        this.setState(state => {
            state = {
                toArtistInCents: toArtist,
                toArtistInputValue: newValue,
                toArtistValid: isValid,
            }
            return state
        })
    }

    previous = () => {
        if (this.animating) return
        const nextIndex =
            this.state.activeIndex === 0
                ? this.props.collection.images.length - 1
                : this.state.activeIndex - 1
        this.setState(state => {
            state.activeIndex = nextIndex
            return state
        })
    }

    goToIndex = newIndex => {
        if (this.animating) return
        this.setState({ activeIndex: newIndex })
    }

    RedirectAfterAnimation = () => {
        const { collection } = this.props
        const { activeIndex } = this.state

        const imgSrc = collection.images[activeIndex]
        const imgId = imgIdFrom(imgSrc)

        const currentLocation = window.location.pathname // should prolly use withRouter
        const currentRoutePath = `/download/${collection.id}/${imgId}`

        return currentLocation.includes(currentRoutePath) ? null : (
            <Redirect to={currentRoutePath} />
        )
    }

    static renderCurrencyInput(changeHandler, value, isValid) {
        const style = {
            background: isValid ? 'white' : 'red',
        }

        return (
            <input
                onChange={changeHandler}
                value={value}
                className="form-control"
                style={style}
                type="number"
                step="0.50"
                max="100"
                min="1"
            />
        )
    }

    render() {
        const { collection } = this.props
        const {
            activeIndex,
            toArtistInputValue,
            toArtistValid,
            toArtistInCents,
            toWildlifeInputValue,
            toWildlifeValid,
            toWildlifeInCents,
            paidImageIndex,
            paymentError,
            paymentSuccess,
        } = this.state
        const totalPaymentInCents = toArtistInCents + toWildlifeInCents
        const isValid = toArtistValid && toWildlifeValid
        const slides = collection.images.map(item => (
            <CarouselItem onExiting={this.onExiting} onExited={this.onExited} key={item}>
                <img src={item} className="img-fluid" />
            </CarouselItem>
        ))

        const downloadSectionClassName = this.state.shown ? '' : 'hidden'

        return (
            <div>
                {this.RedirectAfterAnimation()}

                <nav className="navbar-light bg-color">
                    <Link to={`/main/${collection.id}`} className="navbar-brand py-4">
                        <img
                            src={require('../img/icon_ColorMeLife_simple.svg')}
                            className="brand-icon-sm"
                            alt="Coloring pages for kids and adults"
                            title="Coloring pages for kids and adults"
                        />
                    </Link>
                </nav>
                <div className="container">
                    <div className="mt-4">
                        <Nav tabs className="nav nav-pills nav-fill">
                            <NavItem>
                                <NavLink activeClassName="active" to="/download/whales/">
                                    WHALES
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink activeClassName="active" to="/download/africa">
                                    AFRICA
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink activeClassName="active" to="/download/coral">
                                    CORAL REEF
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink activeClassName="active" to="/download/arctic">
                                    ARCTIC
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink activeClassName="active" to="/download/amazon">
                                    AMAZON
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink activeClassName="active" to="/download/vegetation">
                                    VEGETATION
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </div>
                    <div className="my-5">
                        <h2 className="text-center">{collection.title}</h2>
                        <br />
                        {collection.introText}
                    </div>

                    <div>
                        <Carousel
                            activeIndex={activeIndex}
                            next={this.next}
                            previous={this.previous}
                            interval={0}
                            keyboard={false}
                        >
                            <CarouselIndicators
                                items={slides}
                                activeIndex={activeIndex}
                                onClickHandler={this.goToIndex}
                            />
                            {slides}
                            <CarouselControl
                                direction="prev"
                                directionText="Previous"
                                onClickHandler={this.previous}
                                className="arrow-left"
                            />
                            <CarouselControl
                                direction="next"
                                directionText="Next"
                                onClickHandler={this.next}
                                className="arrow-right"
                            />
                        </Carousel>
                    </div>
                    <div className="text-center px-5 mt-2">{collection.captionText}</div>
                    <div className="px-5 my-2 collapse" id="downloadPrint">
                        <hr />
                    </div>
                    <div className="text-center">
                        <Button className="btn-primary mt-4" onClick={this.toggleDownload}>
                            DOWNLOAD PRINT
                        </Button>
                    </div>
                    <div className={downloadSectionClassName}>
                        <div className="download-section px-3 pb-4">
                            <div className="narrow-col pt-3 text-center">
                                <p>Make sure the image you want is the one visible above.</p>
                                <p>
                                    Your high-resolution image will be ready to download after the
                                    payment has been processed and you'll be able to click on the
                                    icon bellow.
                                </p>
                            </div>
                            <div className="text-center mt-4">
                                {paymentSuccess ? (
                                    <div>
                                        <h2>Thanks!</h2>
                                        <h6>
                                            Your image is ready for download, just click the icon
                                            below.
                                        </h6>
                                        <h6>You just became a wildlife hero!</h6>
                                        <a
                                            download
                                            href={collection.originalImages[paidImageIndex]}
                                        >
                                            <img
                                                className="icon-download-on pb-3"
                                                src={require('../img/download-active.svg')}
                                            />
                                        </a>
                                    </div>
                                ) : (
                                    <div>
                                        <a id="tooltipDownloadDisabled">
                                            <img src={require('../img/download-disabled.svg')} />
                                        </a>
                                        <Tooltip
                                            placement="right"
                                            isOpen={this.state.tooltipOpen}
                                            target="tooltipDownloadDisabled"
                                            toggle={this.toggleTooltip}
                                        >
                                            Pay what you feel is fair, as little as $1 for artist +
                                            $1 for wildlife.
                                        </Tooltip>
                                    </div>
                                )}
                                {paymentError ? (
                                    <div>
                                        There was an error processing your payment, please
                                        <a href="mailto:info@pinkhoof.com">contact us.</a>
                                    </div>
                                ) : null}
                            </div>
                            <form>
                                <div className="row mx-4 my-3 text-center">
                                    <div className="col-sm my-2">
                                        <div className="banner-light-1 p-4">
                                            <h6 className="my-1 font-weight-bold">
                                                Give back to artist
                                            </h6>
                                            <div className="d-inline-flex">
                                                {Download.renderCurrencyInput(
                                                    this.onToArtistChange,
                                                    toArtistInputValue,
                                                    toArtistValid,
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm my-2">
                                        <div className="banner-light-2 p-4">
                                            <h6 className="my-1 font-weight-bold">
                                                Give back to wildlife
                                            </h6>
                                            <div className="d-inline-flex">
                                                {Download.renderCurrencyInput(
                                                    this.onToWildlifeChange,
                                                    toWildlifeInputValue,
                                                    toWildlifeValid,
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="narrow-col total-amount text-center">
                                    <h5 className="my-2">
                                        <b>Total: ${centsToDollars(totalPaymentInCents)} USD</b>
                                    </h5>
                                </div>
                            </form>
                            <div className="text-center">
                                {isValid ? (
                                    <TakeMoney
                                        toWildlife={toWildlifeInCents}
                                        toArtist={toArtistInCents}
                                        collection={collection}
                                        activeIndex={activeIndex}
                                        purchaseId={collection.originalImages[activeIndex]}
                                        onSuccess={this.handlePaymentSuccess}
                                        onError={this.handlePaymentError}
                                    />
                                ) : (
                                    <div>Please check the donation values, thanks.</div>
                                )}
                            </div>
                            <div className="narrow-col text-center py-1">
                                <small>
                                    Please give a few seconds for the image to be ready to download.
                                    If you have any problem please{' '}
                                    <a href="mailto:info@pinkhoof.com">contact us</a>. We use stripe
                                    for payments to make all transactions safe, ColormeLife never
                                    gets access to your card details.
                                </small>
                            </div>
                        </div>
                    </div>

                    <div className="mx-4 my-4 text-center">
                        <p>
                            Our collections are composed of several cut-outs of one large drawing.
                            You can download and color one by one, then put the whole collection
                            together, like a puzzle!
                        </p>
                        <p>
                            If you'd like to have the option to download the whole image at once,
                            let us know by sending a message through our Instagram.
                        </p>
                        <hr />
                    </div>
                    <div className="mx-4 my-4 text-center">
                        <hr />
                        <h6>
                            Some of the organizations we regularly give to are WWF, IAPF, and
                            Freethebears. If you want to learn more about how and why,{' '}
                            <a href="https://twitter.com/PinkHoof">send us smoke signs,</a> happy to
                            happy to chat.
                        </h6>
                    </div>
                    <br />
                </div>
                <div className="footer p-3">
                    <div className="my-2 text-center">
                        <img
                            src={require('../img/instagram_phone.png')}
                            width="120"
                            className="mx-auto d-block p-3"
                        />
                        <p className="text-white">
                            Share your colored art
                            <span className="font-weight-bold">
                                <a href="https://www.instagram.com/colorme_life/" target="_blank">
                                    @colorme_life.
                                </a>{' '}
                                #ColormeLifeCollections
                            </span>
                        </p>
                    </div>
                    <div className="footer-signature text-center mt-5 mb-4">
                        <a href="https://www.instagram.com/colorme_life/" className="text-white">
                            <span className="text-xs pr-3 align-text-top">♥ </span>
                            Thanks for caring and sharing!<span className="text-xs pl-3 align-text-top">
                                ♥
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}

Download.propTypes = {
    collection: PropTypes.object,
    selectedImgSrc: PropTypes.string,
}
