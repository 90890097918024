/* eslint-disable react/no-unescaped-entities */
import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import { Nav, NavItem, TabContent, TabPane } from 'reactstrap'

import ColorMeTabContent from './ColorMeTabContent'

import {
    africaCollection,
    amazonCollection,
    arcticCollection,
    coralCollection,
    vegetationCollection,
    whalesCollection,
} from '../collection'
import OrderBooklet from '../common/orderBooklet'

export default class Main extends Component {
    render() {
        const { activeTab } = this.props

        return (
            <div>
                <div className="jumbotron jumbotron-fluid">
                    <div className="container text-center">
                        <img
                            src={require('../img/icon_ColorMeLife_simple.svg')}
                            className="brand-icon"
                            alt="Coloring pages for kids and adults"
                            title="Coloring pages for kids and adults"
                        />
                        <br />
                        <h1 className="my-2 mx-auto logo-type">
                            Colorme<span className="logo-type-l">L</span>ife
                        </h1>
                        <h1 className="my-4 display-4 display d-none">Coloring pages for all</h1>
                        {/*<div className="p-4">
                            <img
                                src={require('../img/two-covers-one-print-one-cactus.jpg')}
                                className="img-fluid rounded-lg"
                                alt="marketing photo"
                                title="Colorme Life prints photo"
                            />
                        </div>*/}
                        <h2 className="mt-5 mb-2">Print out and get wild!</h2>
                        <div>
                            <p className="lead">
                                Coloring is forever trendy, and the perfect mindfulness activity.
                                <br/>
                                Color your life with our wildlife collections, while helping protect biodiversity.
                            </p>
                        </div>
                    </div>
                    <div className="bn-clipboard text-center">
                        <a
                            href="https://www.instagram.com/colorme_life/"
                            title="#colormeLife"
                            className="btn-clipboard"
                        >
                            <img
                                src={require('../img/ic_social_instagram.svg')}
                                className="float-icon"
                                alt="#colormeLife"
                            />
                        </a>
                    </div>
                </div>

                <div className="container mt-5">
                    <OrderBooklet />
                    <h4 className="my-4 mb-0 text-center display">Collections</h4>
                    <Nav tabs className="nav nav-pills nav-fill my-0">
                        <NavItem>
                            <NavLink activeClassName="active" to="/main/whales">
                                WHALES
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink activeClassName="active" to="/main/africa">
                                AFRICA
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink activeClassName="active" to="/main/coral">
                                CORAL REEF
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink activeClassName="active" to="/main/arctic">
                                ARCTIC
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink activeClassName="active" to="/main/amazon">
                                AMAZON
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink activeClassName="active" to="/main/vegetation">
                                VEGETATION
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="whales">
                            <ColorMeTabContent
                                collectionName="whales"
                                imgSrcArray={whalesCollection.images}
                                altText="Coloring page for adults Printable Whales"
                                titleText="Coloring page for adults Printable Whales"
                            />
                        </TabPane>
                        <TabPane tabId="africa">
                            <ColorMeTabContent
                                collectionName="africa"
                                imgSrcArray={africaCollection.images}
                                altText="Coloring page for adults Printable Elephant Lion Gorilla Zebra Rhino Giraffe"
                                titleText="Coloring page for adults Printable Elephant Lion Gorilla Zebra Rhino Giraffe"
                            />
                        </TabPane>
                        <TabPane tabId="coral">
                            <ColorMeTabContent
                                collectionName="coral-reef"
                                imgSrcArray={coralCollection.images}
                                altText="Coloring page for adults Printable Coral Reef Turtle Fish"
                                titleText="Coloring page for adults Printable Coral Reef Turtle Fish"
                            />
                        </TabPane>
                        <TabPane tabId="arctic">
                            <ColorMeTabContent
                                collectionName="arctic"
                                imgSrcArray={arcticCollection.images}
                                altText="Coloring page for adults Printable Arctic Polar bear Wolverine Owl Seal Bird"
                                titleText="Coloring page for adults Printable Arctic Polar bear Wolverine Owl Seal Bird"
                            />
                        </TabPane>
                        <TabPane tabId="amazon">
                            <ColorMeTabContent
                                collectionName="amazon"
                                imgSrcArray={amazonCollection.images}
                                altText="Coloring page for adults Printable Amazon Jaguar Sloth Lizard Uakari monkey"
                                titleText="Coloring page for adults Printable Amazon Jaguar Sloth Lizard Uakari monkey"
                            />
                        </TabPane>
                        <TabPane tabId="vegetation">
                            <ColorMeTabContent
                                collectionName="vegetation"
                                imgSrcArray={vegetationCollection.images}
                                altText="Coloring page for adults Printable Vegetation Cactus Plants"
                                titleText="Coloring page for adults Printable Vegetation Cactus Plants"
                            />
                        </TabPane>
                    </TabContent>

                    <div className="text-center my-5">
                        <p>
                            With every coloring art you download you'll help support wildlife
                            heroes.
                        </p>
                        <p>
                            Print your drawing on recycled or sustainable paper, or use your
                            preferred coloring app!
                        </p>
                    </div>
                </div>

                <div className="p-5 text-center">
                <h5>Are you an artist and want to help create awareness with your drawings?</h5>
                    <p>Submit your coloring art to be part of the ColormeLife collection and get paid with 💰 and 💜.</p>
                    <Link to='artists'>
                        <p className="btn btn-primary">submit artwork</p>
                    </Link>
                </div>

                <div className="footer">
                    <div className="footer-signature text-center pt-4">
                        <a href="https://www.instagram.com/colorme_life/" className="text-white">
                            <span className="text-xs pr-3 align-text-top">♥ </span>
                            Thanks for caring and sharing!<span className="text-xs pl-3 align-text-top">
                            ♥
                            </span>
                        </a>
                    </div>
                    <div className="text-center py-4">
                        <small>
                            <a
                                href="http://pinkhoof.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Who is behind Colorme Life?
                            </a>
                        </small>
                    </div>
                </div>
            </div>
        )
    }
}

Main.propTypes = {
    activeTab: PropTypes.string,
    toggle: PropTypes.func,
    downloadItem: PropTypes.func,
}
