import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'
import { imgIdFrom } from '../../common/image-id'

export default class ColorMeTabContent extends Component {
    renderTabRows(imgSrcArray, altText, titleText, collectionName) {
        const renderItem = imgSrc => {
            const imgId = imgIdFrom(imgSrc)

            return (
                <Link to={`/download/${collectionName}/${imgId}`} className="col-sm thumbnail-box">
                    <img
                        src={imgSrc}
                        className="img-fluid img-thumbnail"
                        alt={altText}
                        title={titleText}
                    />
                </Link>
            )
        }

        return (
            <div>
                <div className="row mt-5 text-center">
                    {renderItem(imgSrcArray[0])}
                    {renderItem(imgSrcArray[1])}
                    {renderItem(imgSrcArray[2])}
                </div>
                <div className="row text-center">
                    {renderItem(imgSrcArray[3])}
                    {renderItem(imgSrcArray[4])}
                    {renderItem(imgSrcArray[5])}
                </div>
            </div>
        )
    }

    render() {
        const { imgSrcArray, altText, titleText, collectionName } = this.props

        return <div>{this.renderTabRows(imgSrcArray, altText, titleText, collectionName)}</div>
    }
}

ColorMeTabContent.propTypes = {
    altText: PropTypes.string,
    titleText: PropTypes.string,
    collectionName: PropTypes.string,
    imgSrcArray: PropTypes.array,
}
