/* eslint-disable react/no-unescaped-entities */
import React from 'react'

export const africaCollection = {
    id: 'africa',
    title: 'African Savanna',
    introText: (
        <blockquote className="text-center">
            <span className="quote-marks">"</span>
            Of the 5 million species on that planet, only one has the power to determine what level
            of suffering is acceptable for all other sentient beings to endure.
            <footer className="blockquote-footer">Damien Mander</footer>
        </blockquote>
    ),
    captionText: (
        <div>
            <h4 className="text-center">
                The fascinating wildlife of the African Savanna can be incredibly attractive and
                disturbing at the same time, with the variety of predators and beautiful species
                interacting in a warm and dusty scape.
            </h4>
            <p>
                This drawing was inspired by the incredible wild creatures unique to the African
                Savanna and by a strong hope for us human beings to respect and protect all other
                living beings in our incredible world.
            </p>
        </div>
    ),
    images: [
        require('../img_collections/colormelife-coloring-page-printable-africa-1.png'),
        require('../img_collections/colormelife-coloring-page-printable-africa-2.png'),
        require('../img_collections/colormelife-coloring-page-printable-africa-3.png'),
        require('../img_collections/colormelife-coloring-page-printable-africa-4.png'),
        require('../img_collections/colormelife-coloring-page-printable-africa-5.png'),
        require('../img_collections/colormelife-coloring-page-printable-africa-6.png'),
    ],
    originalImages: [
        require('../img_collections/originals/ColormeLife-drawing-africa-1.png'),
        require('../img_collections/originals/ColormeLife-drawing-africa-2.png'),
        require('../img_collections/originals/ColormeLife-drawing-africa-3.png'),
        require('../img_collections/originals/ColormeLife-drawing-africa-4.png'),
        require('../img_collections/originals/ColormeLife-drawing-africa-5.png'),
        require('../img_collections/originals/ColormeLife-drawing-africa-6.png'),
    ],
    caption: ['Africa 1', 'Africa 2', 'Africa 3', 'Africa 4', 'Africa 6'],
}

export const amazonCollection = {
    id: 'amazon',
    title: 'Amazon',
    introText: (
        <blockquote className="text-center">
            <span className="quote-marks">"</span>His stride is wildernesses of freedom:
            <br />
            The world rolls under the long thrust of his heel.
            <br />
            Over the cage floor the horizons come.
            <footer className="blockquote-footer">Ted Hughes</footer>
        </blockquote>
    ),
    captionText: (
        <div>
            <p>This drawing is still in it's original form, high-detailed vectorized version is coming soon</p>
            <h4 className="text-center">
                Across nine countries of South America there is a dense wilderness, an incredibly
                rich area of rain forest that has a deep connection to the global wellness of our
                world.
            </h4>
            <p>
                This drawing was inspired by species living in the Amazon rain forest. The main
                threat to it is deforestation, but deforestation doesn't only threatens the life of
                the amazing species of plants and animals in the Amazon, the release of carbon that
                can affect the global climate is also a mayor concern.
            </p>
        </div>
    ),
    images: [
        require('../img_collections/colormelife-coloring-page-printable-amazon-1.png'),
        require('../img_collections/colormelife-coloring-page-printable-amazon-2.png'),
        require('../img_collections/colormelife-coloring-page-printable-amazon-3.png'),
        require('../img_collections/colormelife-coloring-page-printable-amazon-4.png'),
        require('../img_collections/colormelife-coloring-page-printable-amazon-5.png'),
        require('../img_collections/colormelife-coloring-page-printable-amazon-6.png'),
    ],
    originalImages: [
        require('../img_collections/originals/ColormeLife-drawing-amazon-1.png'),
        require('../img_collections/originals/ColormeLife-drawing-amazon-2.png'),
        require('../img_collections/originals/ColormeLife-drawing-amazon-3.png'),
        require('../img_collections/originals/ColormeLife-drawing-amazon-4.png'),
        require('../img_collections/originals/ColormeLife-drawing-amazon-5.png'),
        require('../img_collections/originals/ColormeLife-drawing-amazon-6.png'),
    ],
}

export const arcticCollection = {
    id: 'arctic',
    title: 'Arctic',
    introText: (
        <blockquote className="text-center">
            <span className="quote-marks">"</span>...these tenacious birds understand the cliché
            <br />it's all about the journey...
            <footer className="blockquote-footer">Denel Kessler</footer>
        </blockquote>
    ),
    captionText: (
        <div>
            <p>This drawing is still in it's original form, high-detailed vectorized version is coming soon</p>
            <h4 className="text-center">
                Ever seen the midnight sun rest still on the other side of a mirror lake? The arctic
                tundra contrasts a scenery between a bright short summer and a cold long winter,
                giving life to a variety of peculiar and vulnerable species.
            </h4>
            <p>
                This drawing was inspired by species living in the North Hemisphere, some of them as
                far away as the Arctic Tundra.
            </p>
        </div>
    ),
    images: [
        require('../img_collections/colormelife-coloring-page-printable-arctic-1.png'),
        require('../img_collections/colormelife-coloring-page-printable-arctic-2.png'),
        require('../img_collections/colormelife-coloring-page-printable-arctic-3.png'),
        require('../img_collections/colormelife-coloring-page-printable-arctic-4.png'),
        require('../img_collections/colormelife-coloring-page-printable-arctic-5.png'),
        require('../img_collections/colormelife-coloring-page-printable-arctic-6.png'),
    ],
    originalImages: [
        require('../img_collections/originals/ColormeLife-drawing-arctic-1.png'),
        require('../img_collections/originals/ColormeLife-drawing-arctic-2.png'),
        require('../img_collections/originals/ColormeLife-drawing-arctic-3.png'),
        require('../img_collections/originals/ColormeLife-drawing-arctic-4.png'),
        require('../img_collections/originals/ColormeLife-drawing-arctic-5.png'),
        require('../img_collections/originals/ColormeLife-drawing-arctic-6.png'),
    ],
}

export const coralCollection = {
    id: 'coral',
    title: 'Coral reef',
    introText: (
        <blockquote className="text-center">
            <span className="quote-marks">"</span>The sea, once it casts its spell, holds one in its
            net of wonder forever.
            <footer className="blockquote-footer">Jacques Y. Cousteau</footer>
        </blockquote>
    ),
    captionText: (
        <div>
            <h4 className="text-center">
                Those magic multicolored organisms dancing on the bottom of shallow ocean waters
                give support to the life of about 2 million species. Coral reefs provide us all with
                valuable resources, they are worth protecting!
            </h4>
            <p>
                This drawing was inspired by the amazingly colorful and peaceful scenery along coral
                reefs.
            </p>
        </div>
    ),
    images: [
        require('../img_collections/colormelife-coloring-page-printable-coralreef-1.png'),
        require('../img_collections/colormelife-coloring-page-printable-coralreef-2.png'),
        require('../img_collections/colormelife-coloring-page-printable-coralreef-3.png'),
        require('../img_collections/colormelife-coloring-page-printable-coralreef-4.png'),
        require('../img_collections/colormelife-coloring-page-printable-coralreef-5.png'),
        require('../img_collections/colormelife-coloring-page-printable-coralreef-6.png'),
    ],
    originalImages: [
        require('../img_collections/originals/ColormeLife-drawing-coralreef-1.png'),
        require('../img_collections/originals/ColormeLife-drawing-coralreef-2.png'),
        require('../img_collections/originals/ColormeLife-drawing-coralreef-3.png'),
        require('../img_collections/originals/ColormeLife-drawing-coralreef-4.png'),
        require('../img_collections/originals/ColormeLife-drawing-coralreef-5.png'),
        require('../img_collections/originals/ColormeLife-drawing-coralreef-6.png'),
    ],
}

export const vegetationCollection = {
    id: 'vegetation',
    title: 'Flora',
    introText: (
        <blockquote className="text-center">
            <span className="quote-marks">"</span>Normality is a paved road: It’s comfortable to
            walk, but no flowers grow on it.
            <footer className="blockquote-footer">Vincent van Gogh</footer>
        </blockquote>
    ),
    captionText: (
        <div>
            <p>This drawing is still in it's original form, high-detailed vectorized version is coming soon</p>
            <h4 className="text-center">
                Where there is no green, there is no life... As simple as that!
            </h4>
            <p>
                This drawing was inspired by wild and domestic plants, natural bush and human-made
                gardens. By the beauty of this mysterious <i>Plantae</i> kingdom that gives life to
                us all.
            </p>
        </div>
    ),
    images: [
        require('../img_collections/colormelife-coloring-page-printable-vegetation-1.png'),
        require('../img_collections/colormelife-coloring-page-printable-vegetation-2.png'),
        require('../img_collections/colormelife-coloring-page-printable-vegetation-3.png'),
        require('../img_collections/colormelife-coloring-page-printable-vegetation-4.png'),
        require('../img_collections/colormelife-coloring-page-printable-vegetation-5.png'),
        require('../img_collections/colormelife-coloring-page-printable-vegetation-6.png'),
    ],
    originalImages: [
        require('../img_collections/originals/ColormeLife-drawing-vegetation-1.png'),
        require('../img_collections/originals/ColormeLife-drawing-vegetation-2.png'),
        require('../img_collections/originals/ColormeLife-drawing-vegetation-3.png'),
        require('../img_collections/originals/ColormeLife-drawing-vegetation-4.png'),
        require('../img_collections/originals/ColormeLife-drawing-vegetation-5.png'),
        require('../img_collections/originals/ColormeLife-drawing-vegetation-6.png'),
    ],
}

export const whalesCollection = {
    id: 'whales',
    title: 'Whales',
    introText: (
        <blockquote className="text-center">
            <span className="quote-marks">"</span>All men live enveloped in the whale-lines. All are
            born with halters round their necks; but it is only when caught in the swift, sudden
            turn of death, that mortals realize the silent, subtle, ever-present perils of life.
            <footer className="blockquote-footer">H. Melville</footer>
        </blockquote>
    ),
    captionText: (
        <div>
            <h4 className="text-center">
                In the vastness of the sea, within the deep blue oceans, live the largest mammal:
                cetacea!
            </h4>
            <h6>
                This drawing was inspired by species like blue whale, sei whale, northern right
                whale, and other beautiful whales. Some of this unfortunately endangered due to
                plastic waste in our oceans, oil and gas exploration, strikes by large ships, overfishing and entangling in fishing nets, between other consequences of daily
                human activity.
            </h6>
            <small>Download, color, and share. #savethewhales #protectbiodiversity @colorme_life</small>
        </div>
    ),
    images: [
        require('../img_collections/colormelife-coloring-page-printable-whales-1.png'),
        require('../img_collections/colormelife-coloring-page-printable-whales-2.png'),
        require('../img_collections/colormelife-coloring-page-printable-whales-3.png'),
        require('../img_collections/colormelife-coloring-page-printable-whales-4.png'),
        require('../img_collections/colormelife-coloring-page-printable-whales-5.png'),
        require('../img_collections/colormelife-coloring-page-printable-whales-6.png'),
    ],
    originalImages: [
        require('../img_collections/originals/ColormeLife-drawing-whales-1.png'),
        require('../img_collections/originals/ColormeLife-drawing-whales-2.png'),
        require('../img_collections/originals/ColormeLife-drawing-whales-3.png'),
        require('../img_collections/originals/ColormeLife-drawing-whales-4.png'),
        require('../img_collections/originals/ColormeLife-drawing-whales-5.png'),
        require('../img_collections/originals/ColormeLife-drawing-whales-6.png'),
    ],
}
