import React, { Component } from 'react'
import PropTypes from 'prop-types'

import StripeCheckout from 'react-stripe-checkout'

// eslint-disable-next-line no-undef
const stripe_public_key = STRIPE_PUBLIC_KEY //from webpack
// eslint-disable-next-line no-undef
const payment_url = PAYMENT_FUNCTION_URL //from webpack

export default class TakeMoney extends Component {
    onToken = (purchaseId, toArtist, toWildlife) => token => {
        token.card.metadata = token.card.metadata || {}
        token.card.metadata.toWildlifeInCents = toWildlife
        token.card.metadata.toArtistInCents = toArtist
        token.card.metadata.purchaseId = purchaseId

        // can't send json or cors will get tricky
        // https://stackoverflow.com/questions/46369912/cloud-functions-for-firebase-triggering-function-on-cors-preflight-request
        fetch(payment_url, {
            method: 'POST',
            body: JSON.stringify(token),
        }).then(response => {
            if (response.status === 200) {
                this.props.onSuccess()
            } else {
                this.props.onError()
            }
        })
    }

    render() {
        const { purchaseId, toArtist, toWildlife } = this.props
        const totalPayment = toArtist + toWildlife

        return (
            <div>
                <StripeCheckout
                    name="colorme.life"
                    token={this.onToken(purchaseId, toArtist, toWildlife)}
                    stripeKey={stripe_public_key}
                    amount={totalPayment}
                    allowRememberMe={false}
                />
            </div>
        )
    }
}

TakeMoney.propTypes = {
    onSuccess: PropTypes.func,
    onError: PropTypes.func,
    purchaseId: PropTypes.string,
    toArtist: PropTypes.number,
    toWildlife: PropTypes.number,
}
